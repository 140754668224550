@import 'variables';
// Placeholder.scss

.Placeholder-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; // 100% of the viewport width
    height: calc(100vh - 46px); // 100% of the viewport height
    font-size: 24px; // Adjust font size as needed
    font-weight: bold; // Make the text bold
    color: $snow; // Dark grey color for the text
    background-color: $dark-void; // Light grey background
}