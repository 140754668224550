.Counter-container {
   display: flex;
   color: white;
}

.Counter-digit {
   height: 45px;
   width: 20px;
   overflow: hidden;
   position: relative;


}

.Counter-number {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 45px;


}