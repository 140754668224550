* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   user-select: none;
   color: #fbfbfb;
}

html,
body,
#root,
.App {
   position: relative;
   width: 100vw;
   height: 100vh;
   overflow: hidden;
   font-family: "Cinzel", serif;
   background-color: black;
}