.slide-button {
  margin-top: 24px;
  margin-bottom: 12px;
  height: 48px;
  padding: 0;
  display: grid;
  border-radius: 8px;
  border: 2px solid black;
  letter-spacing: 0.25px;
  cursor: pointer;
  position: relative;
  background: black;
  color: white;
  font-weight: 80;
  scale: 1.15;
  outline-color: rgba(135, 135, 135, 1);
  outline-offset: 0.25rem;

  &:is(:hover, :focus-visible) {
    --active: 1;
  }

  &:active {
    --pressed: 1;
  }

  > span:nth-of-type(1) {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    position: absolute;
    inset: 0;
    scale: calc(1 - (var(--pressed, 0) * 0.05));
    transition: scale 0.1s;
  }

  &:is(:hover, :focus-visible) .container {
    width: 100%;
  }

  .container {
    --mask-image: url('./assets/icons/chevron.png'); 
    --spread: 24px;
    --size: 28px;
    width: 48px;
    height: 100%;
    background: #fbfbfb;
    position: absolute;
    left: 0;
    transition: width 0.25s;
    border-radius: 6px;
    box-shadow: 0 10px 10px -5px hsl(0 0% 0% / 0.5);
    container-type: inline-size;
  }

  .primary, .complimentary {
    content: "";
    position: absolute;
    inset: 0;
    background: hsl(0 0% 0% / 0.15);
    z-index: 2;
    mask: var(--mask-image) 50% 50% / var(--size) var(--size) no-repeat;
    container-type: inline-size;
  }

  :where(.primary, .complimentary)::after {
    --distance: calc(100cqi + 100%);
    content: "";
    height: calc(var(--size) * 4);
    aspect-ratio: 1;
    position: absolute;
    left: 0%;
    top: 50%;
    translate: -50% -50%;
    background: radial-gradient(hsl(0 0% 0%), transparent);
    animation: fly-by calc((2 - var(--active, 0)) * 1s) infinite linear;
  }

  @keyframes fly-by {
    0% { translate: -100% -50%; }
    100% { translate: var(--distance) -50%; }
  }

  .complimentary {
    content: "";
    position: absolute;
    inset: 0;
    opacity: var(--active, 0);
    transition: opacity 0.25s;
    background: hsl(0 0% 0% / 0.15);
    mask:
      var(--mask-image) calc(50% - (var(--spread) * 1)) 50% / var(--size) var(--size) no-repeat,
      var(--mask-image) calc(50% - (var(--spread) * 2)) 50% / var(--size) var(--size) no-repeat,
      var(--mask-image) calc(50% - (var(--spread) * 3)) 50% / var(--size) var(--size) no-repeat,
      var(--mask-image) calc(50% + (var(--spread) * 1)) 50% / var(--size) var(--size) no-repeat,
      var(--mask-image) calc(50% + (var(--spread) * 2)) 50% / var(--size) var(--size) no-repeat,
      var(--mask-image) calc(50% + (var(--spread) * 3)) 50% / var(--size) var(--size) no-repeat;
  }

  > span:nth-of-type(2) {
    padding: 0 1.1rem 0 calc(42px + 1.1rem);
    display: grid;
    place-items: center;
    height: 100%;
  }
}


/* Media Query for screen width 1367px and below */
@media screen and (max-width: 1366px) {
  .slide-button {
    margin-top: 16px;
    scale: 1.05;
  }
}