.HT-Container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100vw; /* Full width */
   height: 100vh; /* Full height */
}

.HT-Filled,
.HT-Outline {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   display: flex;
   justify-content: center;
   align-items: center;
   width: 75vw; /* Adjust based on viewport width */
   height: auto; /* Adjust height automatically */
   max-width: 2000px; /* Max width */
   max-height: 2000px; /* Max height, maintain aspect ratio */
   pointer-events: none;
}

.HT-Filled {
   z-index: 1;
   svg path,
   svg polygon,
   svg rect,
   svg circle,
   svg ellipse {
      fill: #fbfbfb !important;
   }
}

.HT-Outline {
   z-index: 3;

   svg path,
   svg polygon,
   svg rect,
   svg circle,
   svg ellipse {
      fill: transparent !important;
      stroke: #fbfbfb;
      stroke-width: 1.5px;
      stroke-linejoin: arcs;
   }
   svg .cls-game {
      fill: #f9f5ff !important;
      stroke: none;
      outline: 5px black;
   }
}
