.GlassTabBarContainer {
   display: inline-flex; // Adjusted for inline placement
   justify-content: center;
   align-items: center;
   z-index: 3;
}

.GlassTabBar {
   display: inline-flex; // Use inline-flex to size container to its content
   padding: 8px 0px 4px 0px; // Add padding
   z-index: 5;
   pointer-events: all;
}