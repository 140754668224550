.Item-Display-Sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 2px;
  background-color: rgba(17, 17, 20, 0.8);
  height: 100%;
  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .toggle-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 2;
  }
}

.sidebar-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will keep the checkbox and text on one side and the count on the other */
  width: 100%; /* Full width to fill the sidebar */
  margin: 4px 0;
  padding: 2px 8px;
  cursor: pointer;
  z-index: 1;
}

.sidebar-item input[type="checkbox"] {
  margin-right: 10px; /* Space between the checkbox and the label */
}

.label-text {
  flex-grow: 1; /* Grow to use available space, pushing the count to the right */
  margin-right: 10px; /* Optional: to ensure some spacing between the text and the count */
}

.count {
  margin-left: auto; /* This will push the count to the right */
}

.Item-Display-MotionHighlight {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 0px;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.trait-category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  padding: 12px;
  white-space: nowrap;
  width: 100%;
}
.trait-category h3 span::after {
  content: "";
  display: block;
  width: calc(100% + 10px); /* Adjust width as needed */
  margin-left: -5px; /* Center the line */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Translucent white underline */
  position: absolute;
  bottom: 0px; /* Adjust the position below the text */
  left: 0;
}

.trait-category h3 span {
  display: inline-block; /* Wrap tightly around the text */
  position: relative;
}
