.Item-Image-Container-Wrapper {
   position: absolute;
   left: 0;
   top: 0;
   width: calc(100% - 530px); /* Adjust this width as needed */
   height: calc(100vh - 46px);
   z-index: 10;
   pointer-events: auto;
   transition: width 0.5s ease;
   pointer-events: none;
}

.Item-Image-Container {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr 1fr;
   grid-auto-columns: 1fr;
   grid-auto-rows: 1fr;
   gap: 0px 0px;
   grid-auto-flow: column;
   grid-template-areas:
      "Item-Image-Q1 Item-Image-Q2"
      "Item-Image-Q3 Item-Image-Q4";
   width: 100%;
   height: 100%;
}

.Item-Image-Q1 {
   grid-area: Item-Image-Q1;
}

.Item-Image-Q2 {
   grid-area: Item-Image-Q2;
}

.Item-Image-Q3 {
   grid-area: Item-Image-Q3;
}

.Item-Image-Q4 {
   grid-area: Item-Image-Q4;
}

.Item-Image-Q1,
.Item-Image-Q2,
.Item-Image-Q3,
.Item-Image-Q4 {
   display: flex;
   justify-content: center;
   align-items: center;
   pointer-events: auto;
   flex-direction: column;
}

.Item-Image-Q1 h2,
.Item-Image-Q2 h2,
.Item-Image-Q3 h2,
.Item-Image-Q4 h2 {
   white-space: nowrap;
   overflow: visible;
   padding-left: 8px;
   padding-right: 8px;
   margin-bottom: 0px;
   margin-top: 8px;
}
@media screen and (min-width: 1666px) {
   .Item-Image-Container {
      grid-template-columns: repeat(4, 1fr); // 4 columns
      grid-template-rows: 1fr; // Single row
      grid-template-areas: "Item-Image-Q1 Item-Image-Q2 Item-Image-Q3 Item-Image-Q4";
   }
}
