.number-minted {
   font-size: xx-large;
}
// Complete SCSS file for ShopItemInfoAndPurchase component
.Item-Info-Container {
   display: grid;
   grid-auto-flow: column;
   grid-auto-columns: 1fr;
   grid-auto-rows: 1fr;
   grid-template-columns: 1fr;
   grid-template-rows: 0.3fr 1.7fr auto 3fr 0.3fr;
   gap: 0px 0px;
   grid-template-areas:
      "Item-Info-Heading"
      "Item-Info-Card"
      "Item-Info-Bulletpoints"
      "Item-Info-Mint"
      "Item-Info-Footer";
   width: 100%;
   height: 100%;
   border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.Item-Info-Heading {
   grid-area: Item-Info-Heading;
}

.Item-Info-Card {
   grid-area: Item-Info-Card;
}

.Item-Info-Bulletpoints {
   grid-area: Item-Info-Bulletpoints;
}

.Item-Info-Mint {
   grid-area: Item-Info-Mint;
}

.Item-Info-Footer {
   grid-area: Item-Info-Footer;
}

.Item-Info-Bulletpoints {
   grid-area: Item-Info-Bulletpoints;
   padding: 8px 8px 0px 8px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: flex-start;
   &::-webkit-scrollbar {
      display: none;
   }

   /* Hide scrollbar for IE, Edge, and Firefox */
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
}

.Item-Info-Heading {
   grid-area: Item-Info-Heading;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   padding-top: 8px;
}

.Item-Info-Heading h1 {
   position: relative; /* Set the positioning context for the pseudo-element */
}

.Item-Info-Heading h1 span {
   display: inline-block; /* Wrap tightly around the text */
   position: relative;
}

.Item-Info-Heading h1 span::after {
   content: "";
   display: block;
   width: calc(100% + 10px); /* Adjust width as needed */
   margin-left: -5px; /* Center the line */
   border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Translucent white underline */
   position: absolute;
   bottom: 0px; /* Adjust the position below the text */
   left: 0;
}

.Item-Info-Card {
   grid-area: Item-Info-Card;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

.Item-Info-Mint {
   grid-area: Item-Info-Mint;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   gap: 6px; // Adjust the gap as necessary
   @media (min-width: 1367px) {
      padding-top: 8px;
   }
}

.Item-Info-Footer {
   grid-area: Item-Info-Footer;
}

.mint-button {
   width: 170px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 15px;
   background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 1),
      rgba(245, 245, 245, 1)
   );
   color: black;
   text-align: center;
   border-radius: 4px 4px 4px 4px;
   border: 1px solid rgba(0, 0, 0, 0.2); /* Subtle border */
   box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5),
      /* Primary shadow */ 10px 10px 20px rgba(0, 0, 0, 0.2); /* Secondary shadow for depth */
   transition: transform 0.25s ease, box-shadow 0.25s ease;
   cursor: pointer;
   position: relative;
   margin-top: 8px;
   margin-bottom: 8px;
}

.mint-button:hover {
   transform: translateY(-3px) scale(1.05); /* Slight lift and scale */
   box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.6),
      /* Enhanced shadow on hover */ 15px 15px 30px rgba(0, 0, 0, 0.25); /* Additional depth */
   transform: scale(1.05); /* Enlarge button on hover */
}
.mint-button:active {
   transform: scale(0.9); /* Shrink button on click */
}
.quantity-selector {
   display: flex;
   align-items: center;
   justify-content: center;
}

.quantity-btn {
   width: 30px;
   height: 30px;
   margin: 0 10px;
   background-color: #f0f0f0;
   color: #333;
   border: none;
   border-radius: 5px;
   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
   cursor: pointer;
   transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.quantity-btn:hover {
   transform: translateY(-2px);
   box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.quantity-btn:active {
   transform: translateY(1px);
   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.quantity-input {
   width: 55px; /* Set a fixed width appropriate for your number size */
   max-width: 100%; /* Ensure it doesn't exceed the parent's width */
   margin: 0; /* Remove any default margins */
   padding: 5px 10px; /* Adjust padding as needed */
   text-align: center;
   border: 2px solid #ccc;
   border-radius: 5px;
   font-size: 1em;
   color: #333; /* Dark color for text */
   background-color: #fff; /* Ensure background is white for contrast */
   outline: none;
   -webkit-appearance: none; /* Removes default browser styling */
   -moz-appearance: none;
   appearance: none;
}
