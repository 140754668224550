@import './_variables.scss';

.FAQ-Container {
    max-width: 100%;
    background-color: $dark-void;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba($dark-void, 0.5);

    .FAQ-Item {
        background-color: transparent;
        border-bottom: 1px solid $gluon-grey;

        &:last-child {
            border-bottom: none;
        }

        .FAQ-Question {
            background-color: transparent;
            color: $snow;
            padding: 0.75rem 1rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &:hover {
                background-color: $slate-grey;
            }
        }

        .FAQ-Answer {
            max-height: 0;
            overflow: hidden;
            background-color: $gluon-grey;
            color: $snow;
            transition: max-height 0.2s ease-out, padding 0.2s ease-out; // Adjusted for faster and smoother transition
            padding: 0 1rem;

            &.expanded {
                padding: 0.5rem 1rem;
                max-height: 100vh; // Adjust max-height to fit the content
            }
        }
    }
}

.arrow {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-right: 2px solid $liquid-lava;
    border-bottom: 2px solid $liquid-lava;
    transition: transform 0.2s ease-out; // Adjusted for faster and smoother transition

    &.right {
        transform: rotate(-45deg);
    }

    &.down {
        transform: rotate(45deg);
    }
}