.LWM-ListContainer {
   display: flex;
   flex-direction: column;
}

.LWM-ListItem {
   display: flex;
   align-items: center;
   border-bottom: 1px solid rgba(255, 255, 255, 0.2);
   margin-bottom: 10px;
}

.LWM-Quantity {
   margin-left: 8px;
   flex-shrink: 0;
   min-width: 50px;
}

.LWM-Placeholder {
   visibility: hidden;
}

.LWM-Icon {
   width: 30px;
   height: 30px;
   margin-right: 8px;
   image-rendering: pixelated;
   flex-shrink: 0;

   // Media query for smaller screens
   @media (max-width: 1367px) {
      width: 20px;
      height: 20px;
   }
}

.LWM-Text {
   font-size: large;

   // Media query for smaller screens
   @media (max-width: 1367px) {
      font-size: medium;
   }
}

.LWM-OverflowWrapper {
   overflow-x: visible;
}
