/* GlobalStyles.css */

/* Styling for all scrollable elements */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: transparent; /* Optional: Change to your preferred track color */
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #555; /* Handle color */
  border-radius: 4px; /* Rounded corners for the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444; /* Darker variant on hover */
}
