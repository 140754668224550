.Items-Display-Container {
   height: 100vh;
   overflow: auto;
   width: 100%;
}
.Items-Display-Inner {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: flex-start;
   padding: 0px 20px 20px 20px;
   height: calc(100% - 200px); /* Modify this line */
}





.Load-More-Ref {
   height: 140px;
   width: 100%;
}
.Item-Display-Title {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding-top: 20px;
}
.Item-Display-Title h1 span::after {
   content: "";
   display: block;
   width: calc(100% + 10px); /* Adjust width as needed */
   margin-left: -5px; /* Center the line */
   border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Translucent white underline */
   position: absolute;
   bottom: 0px; /* Adjust the position below the text */
   left: 0;
}

.Item-Display-Title h1 span {
   display: inline-block; /* Wrap tightly around the text */
   position: relative;
}
.Inventory-Tabs-Wrapper {
   width: 100%; // Take the full width of the container
   display: flex; // Use flexbox
   justify-content: center; // Center children horizontally
   padding: 10px 0; // Optional padding for visual spacing
}
.Items-Display-Wrapper {
   display: flex;
   height: 100vh;
   overflow: hidden;
}

.Items-Display-Container {
   flex-grow: 1; // Grows to take up remaining space
   height: 100vh;
   overflow: auto;
}

.Connect-Wallet-Wrapper {
   display: flex;
   justify-content: center; /* Horizontally center the content */
   align-items: flex-start; /* Vertically center the content */
   height: 100%; /* Take the full height of the parent container */
   width: 100%; /* Take the full width of the parent container */
}

.connect-wallet-button {
   padding: 12px 24px;
   background-color: #121212; /* Very dark blackish background */
   color: white;
   border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border for depth */
   border-radius: 5px;
   font-size: 1em;
   text-transform: uppercase;
   cursor: pointer;
   transition: all 0.3s ease;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Softer shadow for a subtle 3D effect */
   outline: none;
   position: relative;
   overflow: hidden; /* Needed for the pseudo-element */
   backdrop-filter: blur(4px); /* Subtle blur effect for glassmorphism */
}

.connect-wallet-button:hover {
   background-color: #1c1c1c; /* Slightly lighter black for hover */
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Stronger shadow for hover */
}

.connect-wallet-button:active {
   background-color: #000; /* Even darker for the active state */
   box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3); /* Inner shadow for a pressed effect */
}

.connect-wallet-button::after {
   content: "";
   position: absolute;
   bottom: 0;
   left: 50%;
   right: 50%;
   background-color: white; /* Keep the underline white for contrast */
   height: 2px;
   transition: left 0.3s ease, right 0.3s ease;
   transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.connect-wallet-button:hover::after {
   left: 0;
   right: 0;
}

/* Remove the underline effect when the button is active/pressed */
.connect-wallet-button:active::after {
   left: 50%;
   right: 50%;
}
