.HB-Hover-Button {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  width: 150px;
  height: 50px;
  background: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  margin-top: 200px;
  align-self: center;
  transition: margin-top 0.3s ease, color 0.3s ease;
  color: lightgrey;

  &:hover {
    color: #ffffff;
  }

  @media (min-width: 1500px) {
    margin-top: 230px;
  }
  @media (min-width: 2000px) {
    margin-top: 300px;
  }

  @media (min-width: 2400px) {
    margin-top: 320px;
  }
}

.HB-Hover-Button-Svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.HB-Hover-Button-Rect {
  filter: drop-shadow(0 0 8px #fff); // Glowing effect
}
