@import 'variables';

// Assuming $gluon-grey, $snow, and other variables are defined in 'variables'
.TraitsBox-Traitboxes-Container {
    display: flex; // Make the container a flex container
    flex-wrap: wrap; // Allow items to wrap to the next line
    justify-content: flex-start; // Align items to the start of the container
    align-items: flex-start; // Align items to the start of the cross-axis
    gap: 8px; // Add some space between the boxes
}

.TraitsBox-Title {
    color: #ffffff;
    font-size: 1rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.TraitsBox-Title span {
    position: relative;

    &::after {
        content: "";
        display: block;
        width: calc(100% + 20px); // Extend the line by 10px on each side
        height: 1px; // Adjust the height to your desired thickness for the underline
        background: rgba(255, 255, 255, 0.2); // Semi-transparent white for subtle effect
        position: absolute;
        bottom: -2px; // Adjust this value to position the underline correctly relative to the text
        left: -10px; // Shift the line 10px to the left
    }
}

.TraitBox {
    background-color: $gluon-grey;
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 8px;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.2); // Adding a thin light border
    transition: border-color 0.3s ease; // Smooth transition for the border color on hover

    &:hover {
        border-color: rgba(255, 255, 255, 0.5); // Brighter border color on hover
    }

    .TraitBox-Type {
        font-size: 12px;
        opacity: 0.7;
        color: $snow;
        position: relative; // Needed for positioning pseudo-elements

        span {
            display: inline; // Ensure the span is inline for the underline to match text width
            border-bottom: 1px solid rgba(255, 255, 255, 0.3); // Static underline for the text
        }
    }

    .TraitBox-Value {
        font-size: 14px;
        font-weight: lighter;
        color: $snow;
        margin: 4px 0;
    }

    .TraitBox-Details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;

        .TraitBox-Percentage {
            opacity: 0.7;
        }

        .TraitBox-Icon {
            font-weight: bold;
        }
    }
}

.TraitBox.active {
    border: 1px solid $liquid-lava; // Example active style
}