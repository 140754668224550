.TC-Container {
  display: grid;
  grid-template-rows: 46px 1fr 86px; /* Header, Content, Footer */
  grid-auto-columns: 1fr;
  width: 100vw; /* Adjusted to full width */
  height: 100vh; /* Full viewport height */
}

.TC-Header {
  grid-area: 1 / 1 / 2 / 2;
}

.TC-Body {
  grid-area: 2 / 1 / 3 / 2;
  position: relative; /* For absolute positioning of motion.div */
}

.TC-Footer {
  grid-area: 3 / 1 / 4 / 2;
  pointer-events: none;
}
