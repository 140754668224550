.Homepage-Items-Display-Container {
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

.Homepage-Items-Display-Inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px 20px 20px;
    height: 100%;
    position: relative;
}





.Homepage-Load-More-Ref {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; // Ensure it spans the entire width of the container
    height: 1px; // Minimize its height to make it virtually invisible
    visibility: hidden; // Make it invisible but still interactable by the IntersectionObserver
}


.Homepage-Item-Display-Title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
}

.Homepage-Item-Display-Title div span::after {
    content: "";
    display: block;
    width: calc(100% + 10px);
    margin-left: -5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: absolute;
    bottom: 4px;
    left: 0;
}

.Homepage-Item-Display-Title div span {
    display: inline-block;
    position: relative;
    font-size: clamp(1.5rem, 2vw, 2.5rem);
    /* Example values */

}

.Homepage-Inventory-Tabs-Wrapper {
    width: 100%; // Take the full width of the container
    display: flex; // Use flexbox
    justify-content: center; // Center children horizontally
    padding: 0 0; // Optional padding for visual spacing
}

.Homepage-Items-Display-Wrapper {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.Homepage-Items-Display-Container {
    flex-grow: 1; // Grows to take up remaining space
    height: 100%;
    overflow: auto;
}

.Homepage-Connect-Wallet-Wrapper {
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
    align-items: flex-start;
    /* Vertically center the content */
    height: 100%;
    /* Take the full height of the parent container */
    width: 100%;
    /* Take the full width of the parent container */
}

.Homepage-connect-wallet-button {
    padding: 12px 24px;
    background-color: #121212;
    /* Very dark blackish background */
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* Subtle border for depth */
    border-radius: 5px;
    font-size: 1em;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    /* Softer shadow for a subtle 3D effect */
    outline: none;
    position: relative;
    overflow: hidden;
    /* Needed for the pseudo-element */
    backdrop-filter: blur(4px);
    /* Subtle blur effect for glassmorphism */
}

.Homepage-connect-wallet-button:hover {
    background-color: #1c1c1c;
    /* Slightly lighter black for hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    /* Stronger shadow for hover */
}

.Homepage-connect-wallet-button:active {
    background-color: #000;
    /* Even darker for the active state */
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
    /* Inner shadow for a pressed effect */
}

.Homepage-connect-wallet-button::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    background-color: white;
    /* Keep the underline white for contrast */
    height: 2px;
    transition: left 0.3s ease, right 0.3s ease;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.Homepage-connect-wallet-button:hover::after {
    left: 0;
    right: 0;
}

/* Remove the underline effect when the button is active/pressed */
.Homepage-connect-wallet-button:active::after {
    left: 50%;
    right: 50%;
}