.GH-GlassHeader {
   background-color: #111014; // Updated Dark Background
   font-size: 14.5px;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   padding: 8px 8px;
   z-index: 20;
   display: flex; // Use flexbox for horizontal alignment
   justify-content: space-between; // Distribute space between elements
   align-items: center; // Center-align items vertically
   border-bottom: 1px solid rgba(255, 255, 255, 0.15);
   backdrop-filter: blur(20px);
   -webkit-backdrop-filter: blur(20px);
   height: 46px; // Specify the height of the header

   .GH-leftIcons {
      display: flex;
      align-items: center;

      .GH-iconLink {
         margin: 0 10px; // Add equal margin to the left and right
         display: flex;
         align-items: center;

         .svg-inline--fa {
            // FontAwesome icons
            height: 20px;
            width: 20px;
            color: #fbfbfb;
         }

         &:last-child {
            margin-right: 0; // No margin after the last icon
         }
      }

      .GH-divider {
         background-color: #878787;
         width: 1px;
         align-self: stretch; // Stretch to fill the height of the parent
         margin: 0 4px; // Margin on both sides of the divider
      }
   }

   .GH-centerContent {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;

      .GH-mageGameLogo {
         height: 46px;
         margin-right: 8px;
      }

      .GH-centerText {
         color: #fbfbfb;
         font-size: 20px;
      }
   }

   .GH-walletInfo {
      display: flex;
      align-items: center;

      .GH-section {
         display: flex;
         align-items: center;
         margin-left: 10px;
         color: #fbfbfb;

         &.GH-connect {
            cursor: pointer;

            span:hover {
               color: #ee721b !important;
            }
         }
      }

      .GH-divider {
         background-color: #878787;
         width: 1px;
         align-self: stretch; // Stretch to fill the height of the parent
         margin-left: 10px;
      }
   }
}