.homepage-whitelist {
    overflow: hidden; // This prevents any child elements from overflowing
    display: flex; // This enables the use of flexbox layout
    justify-content: center; // This centers children horizontally
    align-items: center; // This centers children vertically
    position: relative; // Establishes a positioning context
    height: 100%; // Set the height of the whitelist container
    width: 100%; // Set the width of the whitelist container

    svg {
        position: absolute; // Positions the SVG absolutely within its parent
        max-height: 100%; // Ensures the SVG's maximum height does not exceed its parent
        max-width: 100%; // Ensures the SVG's maximum width does not exceed its parent
        top: 50%; // Align the center of the SVG with the vertical center of its parent
        left: 50%; // Align the center of the SVG with the horizontal center of its parent
        transform: translate(-50%, -50%); // Offset the SVG by half of its width and height to center it
    }
}