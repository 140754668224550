@import 'variables';

.Homepage-Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 420px;
    grid-template-rows:
        minmax(120px, 0.63fr) 1fr 1fr 1fr minmax(120px, 1fr);
    gap: 0px 0px;
    grid-template-areas:
        "Homepage-Logo Homepage-Logo Homepage-Logo Homepage-Info"
        "Homepage-Slideshow Homepage-Slideshow Homepage-Slideshow Homepage-Info"
        "Homepage-Slideshow Homepage-Slideshow Homepage-Slideshow Homepage-Info"
        "Homepage-Slideshow Homepage-Slideshow Homepage-Slideshow Homepage-Info"
        "Homepage-Whitelist Homepage-Whitelist Homepage-Whitelist Homepage-Info";
    width: 100%;
    height: calc(100vh - 46px);
    position: relative;
    /* Enable vertical scrolling on the whole page container if needed */


    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 120px 480px auto auto; // Adjusted for responsiveness
        grid-template-areas:
            "Homepage-Logo"
            "Homepage-Slideshow"
            "Homepage-Info"
            "Homepage-Whitelist";
        overflow-y: auto;


    }
}



@keyframes homepage-scroll {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: -500% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.Homepage-Logo {
    grid-area: Homepage-Logo;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 0px 16px;
    background-image: url('/assets/imgs/clouds.png');
    background-size: 1024px;
    background-repeat: repeat;
    animation: homepage-scroll 918s linear infinite;
    overflow: hidden;
    border-top: 1px solid $slate-grey;
    border-left: 1px solid $slate-grey;

}

.Homepage-Logo svg {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;


}

.Homepage-Info {
    grid-area: Homepage-Info;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    max-height: 100%;
    overflow-y: auto;
    border: 1px solid $slate-grey;


}

.CharacterDex-Title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.CharacterDex-Name {
    font-size: 18px;
    margin-bottom: 20px;
}

.CharacterDex-Card {
    align-self: center; // Ensure the card is centered within its container
}


.Homepage-Logo {
    grid-area: Homepage-Logo;


}

.Homepage-Whitelist {
    grid-area: Homepage-Whitelist;
    border-left: 1px solid $slate-grey;
    border-bottom: 1px solid $slate-grey;
}

.Homepage-Slideshow {
    grid-area: Homepage-Slideshow;
    overflow: hidden;
    border-left: 1px solid $slate-grey;
    border-bottom: 1px solid $slate-grey;
    border-top: 1px solid $slate-grey;
}

.Card-Traits-Container {
    width: 100%; // Make the container full width
    display: flex;
    flex-direction: column;
    align-items: center; // Center the title and card vertically
    justify-content: flex-start;
}

.Item-Name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center; // Center the title
}

.Traits-Box-Container {
    display: flex;
    width: 100%;
    align-items: center;
}

.Homepage-FAQ-Container {
    padding: 16px;
}