.GlassTab {
   width: auto;
   padding-right: 16px;
   height: 40px;
   margin: 0 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #1b1b1e;
   border-radius: 4px 4px 0 0;
   transition: background-color 0.3s ease, width 0.3s ease, height 0.3s ease;
   cursor: pointer;
   position: relative;

   .GlassTab-icon {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      transition: width 0.3s ease, height 0.3s ease;
      padding-bottom: 1px;
      margin: 8px;
      image-rendering: pixelated;
      object-fit: cover;
   }

   .GlassTab-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;

      .GlassTab-title {
         font-weight: bold;
         color: #fbfbfb;
         margin: 0;
         transition: color 0.3s ease;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .GlassTab-subtitle {
         font-weight: normal;
         color: #fbfbfb;
         margin: 0;
         font-size: 0.9em;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         transition: color 0.3s ease;
      }
   }

   &.compressed {
      width: 32px;
      height: 32px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .GlassTab-icon {
         // Optionally adjust icon size if necessary when compressed
      }

      .GlassTab-text {
         display: none;
      }
   }

   &:hover {

      .GlassTab-title,
      .GlassTab-subtitle {
         color: #ffffff;
      }
   }

   &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 99.8%;
      height: 2px;
      background: rgba(255, 255, 255, 0.7);
      transform: scaleX(0);
      transition: transform 0.3s ease;
      transform-origin: left;
      will-change: transform;
   }

   &:hover:not(.active)::after {
      transform: scaleX(1);
   }

   &.active::after {
      transform: scaleX(1);
   }

   &:hover:not(.active) {
      background-color: rgba(135, 135, 135, 0.3);
   }

   &.active {
      background: rgba(135, 135, 135, 0.88);
      border-radius: 4px 4px 0 0;

      .GlassTab-title,
      .GlassTab-subtitle {
         color: #ffffff;
         font-weight: bolder;
      }
   }

   .GlassTab .GlassTab-icon.first-tab-icon {
      width: 28px;
      height: 40px;
   }

   // Media query for small screens
   @media (max-width: 960px) {
      padding-right: 0; // Remove padding-right on small screens
      width: 80px; // Set a fixed width for the tab
      height: 40px; // Adjust height to match the new width for a square appearance
      margin: 4px;

      .GlassTab-icon {
         margin: 8px; // Adjust icon margin if necessary
      }

      .GlassTab-text {
         display: none; // Hide text content on small screens
      }
   }

   @media (max-width: 830px) {
      padding-right: 0; // Remove padding-right on small screens
      width: 70px; // Set a fixed width for the tab
      height: 40px; // Adjust height to match the new width for a square appearance
      margin: 4px;

      .GlassTab-icon {
         margin: 8px; // Adjust icon margin if necessary
      }

      .GlassTab-text {
         display: none; // Hide text content on small screens
      }
   }

   @media (max-width: 740px) {
      padding-right: 0; // Remove padding-right on small screens
      width: 60px; // Set a fixed width for the tab
      height: 40px; // Adjust height to match the new width for a square appearance
      margin: 4px;

      .GlassTab-icon {
         margin: 8px; // Adjust icon margin if necessary
      }

      .GlassTab-text {
         display: none; // Hide text content on small screens
      }
   }

   @media (max-width: 710px) {
      padding-right: 0; // Remove padding-right on small screens
      width: 40px; // Set a fixed width for the tab
      height: 40px; // Adjust height to match the new width for a square appearance
      margin: 4px;

      .GlassTab-icon {
         margin: 8px; // Adjust icon margin if necessary
      }

      .GlassTab-text {
         display: none; // Hide text content on small screens
      }
   }
}