// Define base styles for infoPanel
.infoPanel {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 500px;
  height: calc(100vh - 46px);
  background-color: rgba(223, 113, 34, 0.88);
  backdrop-filter: blur(20px);
  color: black;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: -10px 0 20px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow-y: auto;
  transition: width 0.5s ease;
}
/* Hide horizontal scrollbar for Webkit-based browsers */
.infoPanel::-webkit-scrollbar {
  height: 0; /* For horizontal scrollbars */
}

/* Hide scrollbar for Firefox */
.infoPanel {
  scrollbar-width: none; /* For horizontal and vertical scrollbars */
}

/* Hide scrollbar for IE/Edge */
.infoPanel {
  -ms-overflow-style: none; /* For horizontal and vertical scrollbars */
}
