.Inventory-Tabs-Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0px;
}

.Inventory-Tabs-Tab {
  position: relative;
  list-style: none;
  cursor: pointer;
  height: 30px;
  padding: 0 10px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.Inventory-Tabs-Span {
  user-select: none;
  font-size: 1rem;
  color: #e8e8fd;
  z-index: 1;
}

.Inventory-Tabs-MotionHighlight {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  background-color: rgba(58, 58, 73, 0.8);
  border-radius: 8px;
  z-index: 0;
}

.Inventory-Tabs-SelectedIndicator {
  position: absolute;
  bottom: -8px;
  left: 0px;
  right: 0;
  height: 4px;
  background: #afafc0;
  border-radius: 8px;
  z-index: 0;
}
