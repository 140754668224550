.number-minted {
   font-size: xx-large;
}
// Complete SCSS file for ShopItemInfoAndPurchase component
.Inv-Item-Info-Container {
   display: grid;
   grid-auto-flow: column;
   grid-auto-columns: 1fr;
   grid-auto-rows: 1fr;
   grid-template-columns: 1fr;
   grid-template-rows: 0.3fr 1.7fr auto 3fr 0.3fr;
   gap: 0px 0px;
   grid-template-areas:
      "Inv-Item-Info-Heading"
      "Inv-Item-Info-Card"
      "Inv-Item-Info-Bulletpoints"
      "Inv-Item-Info-Mint"
      "Inv-Item-Info-Footer";
   width: 100%;
   height: 100%;
   border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.Inv-Item-Info-Heading {
   grid-area: Inv-Item-Info-Heading;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   padding-top: 8px;
}

.Inv-Item-Info-Card {
   grid-area: Inv-Item-Info-Card;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

.Inv-Item-Info-Bulletpoints {
   grid-area: Inv-Item-Info-Bulletpoints;
   padding: 8px 8px 0px 8px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: flex-start;
   &::-webkit-scrollbar {
      display: none;
   }

   /* Hide scrollbar for IE, Edge, and Firefox */
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
}

.Inv-Item-Info-Mint {
   grid-area: Inv-Item-Info-Mint;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   gap: 8px; // Adjust the gap as necessary
   padding-bottom: 20px;
   @media (min-width: 1367px) {
      padding-top: 8px;
   }
}

.Inv-Item-Info-Footer {
   grid-area: Inv-Item-Info-Footer;
}

.Inv-Item-Info-Heading h1 {
   position: relative; /* Set the positioning context for the pseudo-element */
}

.Inv-Item-Info-Heading h1 span {
   display: inline-block; /* Wrap tightly around the text */
   position: relative;
}

.Inv-Item-Info-Heading h1 span::after {
   content: "";
   display: block;
   width: calc(100% + 10px); /* Adjust width as needed */
   margin-left: -5px; /* Center the line */
   border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Translucent white underline */
   position: absolute;
   bottom: 0px; /* Adjust the position below the text */
   left: 0;
}

.Inv-Claim-Button {
   width: 160px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: #fbfbfb;
   color: black;
   text-align: center;
   border-radius: 4px;
   border: 1px solid rgba(0, 0, 0, 0.2); /* Subtle border */
   box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5),
      /* Primary shadow */ 10px 10px 20px rgba(0, 0, 0, 0.2); /* Secondary shadow for depth */
   transition: transform 0.25s ease, box-shadow 0.25s ease;
   cursor: pointer;
   position: relative;
}

.Inv-Claim-Button:hover {
   transform: translateY(-3px) scale(1.05); /* Slight lift and scale */
   box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.6),
      /* Enhanced shadow on hover */ 15px 15px 30px rgba(0, 0, 0, 0.25); /* Additional depth */
}

.Inv-Claim-Button:active {
   transform: scale(0.9); /* Shrink button on click */
}

.Inv-Claim-Grid {
   display: grid;
   grid-template-columns: repeat(2, 1fr); // Creates a 2-column grid
   grid-template-rows: auto auto; // 2 rows, size according to content
   gap: 20px; // Adjust spacing as needed
   justify-content: center; // Center grid items horizontally
   align-items: center; // Center grid items vertically
}

.Inv-Claim-Cell {
   // Styles for the grid cells
   display: flex;
   justify-content: center; // Center button in the cell
   align-items: center; // Center button in the cell
}

.Token-Selection-Container {
   display: flex; /* Establish a flex container */
   align-items: center; /* Align items vertically */
   justify-content: space-between; /* Place navigation buttons on the sides */
   padding: 5px;
   min-height: 230px;
   width: 100%;
}

.Inventory-Item-Token-Id-Selection-Grid {
   display: flex;
   flex-wrap: wrap;
   gap: 1px;
   justify-content: center;
   align-items: center;
   flex-grow: 1;
}

.Inventory-Item-Token-Id-Row {
   display: flex;
   justify-content: center;
   width: 100%;
}

.Inventory-Item-Pushable {
   position: relative;
   border: none;
   background: transparent;
   padding: 0;
   cursor: pointer;
   outline-offset: 4px;
   transition: filter 250ms, background-color 0.3s ease;
   width: 40px;
   height: 40px;
   margin: 1px;
   border-radius: 10px;
   backdrop-filter: blur(4px);
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

   &:active .Inventory-Item-Front {
      transform: translateY(-1px);
      transition: transform 34ms;
   }

   &:focus:not(:focus-visible) {
      outline: none;
   }
}

.Inventory-Item-Shadow,
.Inventory-Item-Edge {
   display: none;
}

.Inventory-Item-Front {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   width: 100%;
   height: 100%;
   border-radius: 10px;
   font-size: 15px;
   color: rgba(255, 255, 255, 0.9);
   background: rgba(0, 0, 0, 0.3);
   transition: background-color 0.3s ease,
      transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
   user-select: none;
   &:hover {
      outline: 1px solid white; /* White outline on hover */
   }
   &.selected {
      background: rgba(255, 255, 255, 0.78);
      color: #000;
   }
}

.Inventory-Item-Pushable:active .Inventory-Item-Front,
.Inventory-Item-Pushable:hover .Inventory-Item-Front {
   background-color: rgba(255, 255, 255, 0.3);
}

.Pagination-Controls {
   display: flex;
   justify-content: space-between;
   width: 100%; // Full width to utilize the entire row
   position: relative;
   order: 2; // Adjus
}

.Page-Navigation-Button {
   background: rgba(
      255,
      165,
      0,
      0.7
   ); /* Adjusted color for better visibility */
   border: none;
   padding: 10px 8px; /* Increased padding for a larger button */
   border-radius: 5px; /* Adjusted border-radius for aesthetic */
   cursor: pointer;
   backdrop-filter: blur(5px); /* Apply a blur to the background */
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adjusted shadow for depth */
   color: white; /* Button text color */
   font-size: 16px; /* Increased font size */
   margin: 0 2px; /* Space between buttons and token grid */
   transition: background-color 0.3s ease, transform 0.2s ease;

   &:hover {
      background-color: rgba(255, 165, 0, 0.8); /* Darker tint on hover */
      transform: scale(1.05); /* Slight scale effect on hover */
   }

   &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background: rgba(255, 165, 0, 0.3); /* More transparency when disabled */
   }

   /* Adding an icon instead of text arrow */
   &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
   }

   /* Styling for the left arrow button */
   &.left-arrow:before {
      background-size: contain; /* Ensure the icon fits within the button */
      width: 1em; /* Icon width */
   }

   /* Styling for the right arrow button */
   &.right-arrow:before {
      background-size: contain; /* Ensure the icon fits within the button */
      width: 1em; /* Icon width */
   }
}

.Claim-Button {
   width: 130px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 15px;
   background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 1),
      rgba(245, 245, 245, 1)
   );
   color: black;
   text-align: center;
   border-radius: 4px 4px 4px 4px;
   border: 1px solid rgba(0, 0, 0, 0.2); /* Subtle border */
   box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5),
      /* Primary shadow */ 10px 10px 20px rgba(0, 0, 0, 0.2); /* Secondary shadow for depth */
   transition: transform 0.25s ease, box-shadow 0.25s ease;
   cursor: pointer;
   position: relative;
   margin-top: 20px;
   margin-bottom: 20px;
}

.Claim-Button:hover {
   transform: translateY(-3px) scale(1.05); /* Slight lift and scale */
   box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.6),
      /* Enhanced shadow on hover */ 15px 15px 30px rgba(0, 0, 0, 0.25); /* Additional depth */
   transform: scale(1.05); /* Enlarge button on hover */
}
.Claim-Button:active {
   transform: scale(0.9); /* Shrink button on click */
}
.Claim-Button-Container {
   display: flex;
   justify-content: center; /* Center button horizontally */
   align-items: flex-start;
}

.Inventory-Item-Subheading {
   position: relative; // Parent relative positioning context for the absolute children
   display: flex; // Use flexbox for easy vertical alignment
   flex-direction: column; // Stack children vertically
   align-items: center; // Center children horizontally
   justify-content: center; // Center children vertically
   width: 100%;
}

.Inventory-Item-Subheading h2 {
   width: 100%; // Take the full width of the container
   display: flex; // Use flexbox to allow for the span and button to be inline
   justify-content: center; // Center the text
   position: relative; // For the absolute positioning of the pseudo-element and 'X' button
   flex-grow: 1; // Allow the h2 to grow and fill the container height
   align-items: flex-end; // Align the text to the bottom of the h2
}

.Inventory-Item-Subheading h2 span {
   position: relative; // Relative position for the pseudo-element to be based on
   padding-bottom: 1px; // Adjust for the underline position
}

.Inventory-Item-Subheading h2 span::after {
   content: "";
   display: block;
   width: 100%; // The width will be as wide as the content of the span
   height: 1px;
   background-color: rgba(255, 255, 255, 0.2); // Translucent white underline
   position: absolute;
   bottom: 0; // Position at the bottom of the span
   left: 50%;
   transform: translateX(-50%); // Center the line
}

.Exit-Button {
   position: absolute;
   bottom: -10px; // Adjust as needed, giving space from the bottom of the container
   right: 30px; // Adjust as needed, giving space from the right of the container
   font-size: 20px;
   border: none;
   background-color: transparent;
   color: #fff;
   cursor: pointer;
   // ... other styles
}
